// Global PrimeNG custom styles
.btn-primary-500-xs {
  @apply border-branding-primary-500 bg-branding-primary-500 text-xs text-palette-base-white;
}
.btn-primary-600-xs {
  @apply border-branding-primary-600 bg-branding-primary-600 text-xs text-palette-base-white;
}
.btn-primary-700-xs {
  @apply border-branding-primary-700 bg-branding-primary-700 text-xs text-palette-base-white;
}
.dropdown-language {
  .p-dropdown:not(.p-disabled).p-focus {
    border-color: none !important;
    box-shadow: none !important;
  }
  .p-dropdown-trigger {
    display: none;
  }
}

.overlay-language-setting {
  margin-top: 12px;
}
.overlay-language-setting {
  .p-dropdown-items {
    padding: 0px !important;
  }

  .p-dropdown-items-wrapper {
    border-radius: 8px;
  }
}

.booking-issue-status-note-dialog .p-dialog {
  .p-dialog-header {
    padding: 20px 24px !important;
  }
  .update-changes-dialog .p-dialog-header,
  .discard-changes-dialog .p-dialog-header {
    padding: 16px !important;
  }

  .p-dialog-header .p-dialog-title {
    font-size: 18px !important;
    color: #101828 !important;
    font-weight: 600;
    line-height: 28px;
  }
}

.fare-rule-content {
  ol, ul, menu {
    list-style: unset !important;
    margin: unset !important;
    padding-left: 36px !important;
}
table {
  margin-top: 5px;
  width: 100%;
}
table tr:first-child th{
  background: #eaecf0;
}
table, th, td {
  border: 1px solid #344054;
  border-collapse: collapse;
  padding: 5px;
}
}

.admin-support {
  .p-input-icon-left {
  }
  .px-2 {
    padding-right: 0.75rem !important;
  }
  .p-input-icon-left > .p-inputtext {
    padding-left: 2.5rem !important;
  }
  .w-10 {
    width: 2.5rem !important;
  }
  .rounded-\[50\%\] {
    border-radius: 50% !important;
  }
  .text-xl {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }
  .py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .m-3 {
    margin: 0.75rem !important;
  }
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .gap-6 {
    gap: 1.5rem !important;
  }
  .text-md {
    font-size: 14px !important;
    white-space: nowrap;
  }

  .t-pending-item .flex {
    height: 62px !important;
  }

  .t-pending-item .flex .flex-col {
    justify-content: center !important;
  }
  .w-80 {
    width: 20rem !important;
    min-width: 20rem !important;
  }

  .grid {
    margin: 0px !important;
  }

  .p-inputtextarea.p-inputtext {
    max-height: 42px !important;
    min-height: 42px !important;
    height: 42px !important;
    font-size: 14px;
    line-height: 20px;
  }

  .p-inputtext:enabled:focus {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 8px;
    border: 1px solid #84CAFF !important;
    background: var(--base-white, #FFF);
    box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF !important;
  }

  .mt-6{
    margin-top: 1.5rem !important;
  }

  .mt-5 {
    margin-top: 1.25rem !important;
  }

}

.logout-popup-confirm {
  .p-dialog-content{
    padding-bottom: 24px !important;
  }
}

// booking helpdesk table
.list-booking-helpdesk {
  .p-datatable .p-datatable-thead > tr > th {
    padding: 16px 24px;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 8px 24px;
  }
}

.cancel-language-btn .p-button:enabled:hover,
.confirm-language-btn .p-button:enabled:hover {
  background: #175cd3 !important;
  color: #ffffff !important;
  border-color: transparent !important;
}

.confirm-language-btn  .p-button:focus,
.cancel-language-btn .p-button:focus {
  box-shadow: none !important;
  outline: none !important;

}

.cancel-language-btn .p-button:enabled:hover {
  background: #ffffff !important;
  color: #344054 !important;
  border-color: #d0d5dd !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s !important;
  border-radius: 8px;
  border: 1px solid #84CAFF !important;
  background: var(--base-white, #FFF) !important;
  box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #84CAFF !important;
}


.language-setting {
  .p-dropdown-filter-container {
      border: 1px solid #84caff;
      border-radius: 4px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      margin: 4px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: #495057;
      margin: 4px;
      border-radius: 6px;
      border: 1px solid #e9ecef;
      background: #e9ecef;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      margin: 6px;
      color: #1570EF !important;
      border-radius: 6px;
      background: #d9d9d9;
  }
}

.default-lang-dialog.p-dialog .p-dialog-header,
.default-lang-dialog.p-dialog .p-dialog-footer,
.default-lang-dialog.p-dialog .p-dialog-content{
  background-color: #ffffff !important; 
}

.default-lang-dialog.p-dialog .p-dialog-content {
   border-bottom-left-radius: 0px !important; 
   border-bottom-right-radius: 0px !important; 
}

.fare-rule-content {
  ol, ul, menu {
    list-style: unset !important;
    margin: unset !important;
    padding-left: 36px !important;
}
table {
  margin-top: 5px;
  width: 100%;
}
table tr:first-child th{
  background: #eaecf0;
}
table, th, td {
  border: 1px solid #344054;
  border-collapse: collapse;
  padding: 5px;
}
}
.soc-chat{
  .p-inputtextarea.p-inputtext{
    max-height: 42px !important;
    min-height: 42px !important;
    height: 42px !important;
    font-size: 14px;
    line-height: 20px;
  }
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.pb-2 {
  padding-bottom: .5rem !important;
}
.px-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
}
 .pb-2 {
  padding-bottom: .5rem !important;
}
 .pb-2 {
  padding-bottom: .5rem !important;
}

.p-inputtext:enabled:focus {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
  border: 1px solid #84CAFF !important;
  background: var(--base-white, #FFF);
  box-shadow: 0px 0px 0px 4px #EAF3FF, 0px 1px 2px 0px #84CAFF !important;
}
.grid {
  margin: 0px !important;
}
}

// ************* calendar filter **********************

// input
.fl-input-1 {
  @apply h-9 min-h-9;
}

.fl-input-1:enabled:hover {
  @apply border-palette-blue-300
}

.fl-input-1:enabled:focus {
  @apply outline-none outline-offset-0 border-palette-blue-300 shadow-[0_0_0_4px_#EAF3FF,0_1px_2px_0_rgba(16,24,40,0.05)];
}

// calendar
.fl-calendar-1 .p-datepicker {
  @apply p-0 font-inter;
}

.fl-calendar-1 .p-datepicker table td {
  @apply p-0 font-inter font-normal;
  font-size: 14ppx;
}

.fl-calendar-1 .p-datepicker .p-datepicker-header {
  @apply h-12;
}

.fl-calendar-1 .p-datepicker .p-datepicker-title .p-datepicker-month {
  @apply font-inter;
  font-size: 14px;
}

.fl-calendar-1 .p-datepicker .p-datepicker-header
.p-datepicker-title .p-datepicker-year, .p-datepicker
.p-datepicker-header .p-datepicker-title .p-datepicker-month {
  @font-inter;
  font-size: 14px;
}

.fl-calendar-1 .p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  @apply shadow-none;
}

.fl-calendar-1 .p-datepicker-calendar td .start-date {
  background-color: #1570EF;
  color: white;
  border-radius: 50%;
}

.fl-calendar-1 .p-datepicker-calendar td .end-date  {
  background-color: #1570EF;
  color: white;
  border-radius: 50%;
}

.fl-calendar-1 .p-datepicker-calendar td .in-range {
  background-color: #F2F4F7;
  color: #344054;
  border-radius: 0;
}

.fl-calendar-1 .p-datepicker table td.p-datepicker-today > span {
  background-color: white;
  color: #1570EF;
  border: 1px solid #1570EF;
  border-radius: 50%;
}

.fl-calendar-1 .p-datepicker table td > span:focus {
  @apply outline-none outline-offset-0 border-palette-blue-300 shadow-none bg-white text-palette-blue-600;
}

.fl-calendar-1 .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  border-radius: 50%;
  color: white;
  background-color: #1570EF;
  opacity: 50%;
}