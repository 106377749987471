.icon {
  /*
     still inline, but has block features
     meaning height and width can be set
  */
  display: inline-block;
  height: 20px;
  width: 20px;

  &.icon-home-line {
    background: url('/assets/icons/home-line.svg');
    background-repeat: no-repeat;
    background-size: cover; /* stretch the background to cover the whole element */
  }

  &.icon-booking {
    background: url('/assets/icons/booking.svg');
    background-repeat: no-repeat;
    background-size: cover; /* stretch the background to cover the whole element */
  }

  &.icon-crown {
    background: url('/assets/icons/crown.svg');
    background-repeat: no-repeat;
    background-size: cover; /* stretch the background to cover the whole element */
  }
}

//Hover
.p-button {
  &:hover {
    &.menu-header {
      @apply bg-[#EFF8FF] text-[#1570EF];
    }
    .icon {
      &.icon-home-line {
        transition: all;
        background: url('/assets/icons/home-line-hover.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
